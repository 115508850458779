import apiEndpoints from '@/models/common/api-endpoints';
import dateTimeFormatter from '@/models/common/date-time-formatter';
import dayjs from 'dayjs';
import witnessFilter from '@/components/WitnessFilter';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'witness_statistics',
  components: {
    witnessFilter
  },
  data () {
    return {
      /**
       * Guide to the state structure:
       *  - Each API endpoint used by the page has a state
       *  - Each part has
       *    - loading - flag
       *    - error - flag
       *    - data - response
       *    - calc - output values from response
       */
      nonProcedureData: {
        loading: true,
        error: false,
        data: undefined,
        calc: undefined
      },
      witnessPointData: {
        loading: true,
        error: false,
        data: undefined,
        calc: undefined
      },

      errorMessage: null,
      showError: false
    };
  },

  // ************
  // * Computed *
  // ************

  computed: {
    loading: function () {
      if (this.nonProcedureData.loading || this.witnessPointData.loading) {
        return true;
      }
      return false;
    },
    assignCount: function () {
      if (this.nonProcedureData.calc) {
        if (this.nonProcedureData.includeAssigns) {
          if (this.nonProcedureData.calc.get('ASSIGN')) {
            return this.nonProcedureData.calc.get('ASSIGN').count;        
          }
          else {
            return '0';
          }
        }
      }
      return '-';
    },
    assignName: function () {
      if (this.nonProcedureData.calc && this.nonProcedureData.calc.get('ASSIGN')) {
        if ( this.nonProcedureData.calc.get('ASSIGN').count == 1) {
          return 'Admin Assign';
        }
      }
      return 'Admin Assigns';
    },
    assignRatio: function () {
      if (this.nonProcedureData.calc) {
        if (this.witnessPointData.calc && this.nonProcedureData.calc.get('ASSIGN') && this.nonProcedureData.calc.get('ASSIGN').count > 0 && this.witnessPointData.calc.countwithduration > 0) {
          return parseFloat((this.nonProcedureData.calc.get('ASSIGN').count / this.witnessPointData.calc.countwithduration * 1000).toFixed(1)).toString();
        }
        else if (this.nonProcedureData.calc && !this.nonProcedureData.calc.get('ASSIGN')) {
          return '0';
        }
      }
      return '-';
    },
    discardCount: function () {
      if (this.nonProcedureData.calc) {
        if (this.nonProcedureData.includeDiscards) {
          if (this.nonProcedureData.calc.get('DISCARD')) {
            return this.nonProcedureData.calc.get('DISCARD').count;        
          }
          else {
            return '0';
          }
        }
      }
      return '-';
    },
    discardName: function () {
      if (this.nonProcedureData.calc && this.nonProcedureData.calc.get('DISCARD')) {
        if ( this.nonProcedureData.calc.get('DISCARD').count == 1) {
          return 'Discard';
        }
      }
      return 'Discards';
    },
    mismatchCount: function () {
      if (this.nonProcedureData.calc) {
        if (this.nonProcedureData.includeMismatches) {
          if (this.nonProcedureData.calc.get('MISMATCH')) {
            return this.nonProcedureData.calc.get('MISMATCH').count;        
          }
          else {
            return '0';
          }
        }
      }
      return '-';
    },
    mismatchName: function () {
      if (this.nonProcedureData.calc && this.nonProcedureData.calc.get('MISMATCH')) {
        if ( this.nonProcedureData.calc.get('MISMATCH').count == 1) {
          return 'Mismatch';
        }
      }
      return 'Mismatches';
    },
    mismatchRatio: function () {
      if (this.nonProcedureData.calc)
      {
        if (this.witnessPointData.calc && this.nonProcedureData.calc.get('MISMATCH') && this.nonProcedureData.calc.get('MISMATCH').count > 0 && this.witnessPointData.calc.countwithduration > 0) {
          return parseFloat((this.nonProcedureData.calc.get('MISMATCH').count / this.witnessPointData.calc.countwithduration * 1000).toFixed(1)).toString();
        }
        else if (!this.nonProcedureData.calc.get('MISMATCH')) {
          return '0';
        }
      }
      return '-';
    },
    witnessCount: function () {
      if (this.witnessPointData.calc) {
        return this.witnessPointData.calc.count;
      }else if (!this.witnessPointData.loading && !this.witnessPointData?.error) {
        return '0';
      }
      return '-';
    },
    witnessName: function () {
      if (this.witnessPointData.calc) {
        if (this.witnessPointData.calc.count == 1) {
          return 'Witness Point';
        }
      }
      return 'Witness Points';
    },
    witnessTotalTime: function () {
      if (this.witnessPointData.calc && this.witnessPointData.calc.totaltimeseconds > 0) {
        return dateTimeFormatter.deconstructDateTimeSeconds(this.witnessPointData.calc.totaltimeseconds);
      }else if (!this.witnessPointData.loading && !this.witnessPointData?.error) {
        return '00:00';
      }
      return '-';
    }
  },


  // *********
  // * Watch *
  // *********

  watch: {
    'nonProcedureData.data': {
      handler: function (newVal, oldVal) {
        if (this.nonProcedureData.data) {
          this.updateNonProcedureData();
        }
      }
    },
    'witnessPointData.data': {
      handler: function (newVal, oldVal) {
        if (this.witnessPointData.data) {
          this.updateWitnessPointData();
        }
      }
    }
  },
  mounted() {
    
  },

  // ***********
  // * Methods *
  // ***********

  methods: {
    applyFilters: async function (clinicIds, nonProcedureEvents, events, locations, operators, startDate, endDate) {
      // Clear output  
      this.nonProcedureData.calc = undefined;
      this.witnessPointData.calc = undefined;
      
      this.nonProcedureData.includeAssigns = nonProcedureEvents.includeAssigns;
      this.nonProcedureData.includeDiscards = nonProcedureEvents.includeDiscards;
      this.nonProcedureData.includeMismatches = nonProcedureEvents.includeMismatches;
      
      this.fetchNonProcedureData(clinicIds, nonProcedureEvents, locations, operators, startDate, endDate);
      this.fetchWitnessPointData(clinicIds, events, locations, operators, startDate, endDate);
    },
    fetchNonProcedureData: async function (clinicIds, nonProcedureEvents, locations, operators, startDate, endDate) {
      this.nonProcedureData.data = undefined;
      this.nonProcedureData.calc = undefined;
      this.nonProcedureData.loading = true;
      this.nonProcedureData.error = false;
      
      const witnessPoints = [];
      const response = await apiEndpoints.getAllWitnessEvents(clinicIds, nonProcedureEvents, witnessPoints, locations, operators, startDate, endDate, 'witnesspoint', null, null, 'statisticsViewFetchNonProcedureData');
      // const response = await apiEndpoints.getAllWitnessEvents({
      //   clinicIds: clinicIds,
      //   nonProcedureEvents: nonProcedureEvents,
      //   events: witnessPoints,
      //   locations: locations,
      //   operators: operators,
      //   startDate: startDate,
      //   endDate: endDate,
      //   groupby: 'witnesspoint',
      //   cancelKey: 'statisticsViewFetchNonProcedureData'
      // });

      if (response.result && response.mostRecentWithCancelKey ) {
        this.nonProcedureData.data = response.data;
        // NOTE: UI updated when watch notices new data - more testable
      } else if (response.result && !response.mostRecentWithCancelKey) {
        return;
      }
      else if (!response.result && response.error.isCancel) {
        return;
      } else {
        this.nonProcedureData.error = true;
      }
      
      this.nonProcedureData.loading = false;
    },
    fetchWitnessPointData: async function (clinicIds, events, locations, operators, startDate, endDate) {
      this.witnessPointData.data = undefined;
      this.witnessPointData.calc = undefined;
      this.witnessPointData.loading = true;
      this.witnessPointData.error = false;
      
      const maskNonProcedureEvents = {
        includeAssigns: false,
        includeDiscards: false,
        includeMismatches: false
      };
      
      const response = await apiEndpoints.getAllWitnessEvents(clinicIds, maskNonProcedureEvents, events, locations, operators, startDate, endDate, 'all', null, null, 'statisticsViewFetchWitnessPointData');
      // const response = await apiEndpoints.getAllWitnessEvents({
      //   clinicIds: clinicIds,
      //   nonProcedureEvents: maskNonProcedureEvents,
      //   events: events,
      //   locations: locations,
      //   operators: operators,
      //   startDate: startDate,
      //   endDate: endDate,
      //   groupby: 'all',
      //   cancelKey: 'statisticsViewFetchWitnessPointData'
      // });
     
      if (response.result && response.mostRecentWithCancelKey) {
        this.witnessPointData.data = response.data;
        // NOTE: UI updated when watch notices new data - more testable
      } else if (response.result && !response.mostRecentWithCancelKey) {
        return;
      } else if (!response.result && response.error.isCancel) {
        return;
      } else {
        this.witnessPointData.error = true;
      }
      
      this.witnessPointData.loading = false;
    },
    updateNonProcedureData: function () {
      this.nonProcedureData.calc = new Map();
      if (this.nonProcedureData.data.length > 0) {
        for (let ix = 0; ix < this.nonProcedureData.data.length; ix++) {
          if (this.nonProcedureData.data[ix].eventtype == 'ASSIGN') {
            this.nonProcedureData.calc.set('ASSIGN', this.nonProcedureData.data[ix]);
          }
          if (this.nonProcedureData.data[ix].eventtype == 'DISCARD') {
            this.nonProcedureData.calc.set('DISCARD', this.nonProcedureData.data[ix]);
          }
          if (this.nonProcedureData.data[ix].eventtype == 'MISMATCH') {
            this.nonProcedureData.calc.set('MISMATCH', this.nonProcedureData.data[ix]);
          }
        }
      }
    },
    updateWitnessPointData: function () {
      this.witnessPointData.calc = this.witnessPointData.data[0];
    }
  }
};
