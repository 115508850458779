<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <v-row class="smy_filter_section">
        <v-col>
        <witnessFilter @applyFilters="applyFilters"></witnessFilter>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="smy_contain">
    <v-row>
      <v-col>
        <v-card tile flat color="#F6F7F9">
          <v-card-text class="smy_card">
            <v-container fluid class="pa-0 ma-0">
              <v-row>
                <v-col xs=12 class="pb-0 mb-0 mt-0 pt-0">
                  <v-progress-linear
                    color="primary"
                    :active="loading"
                    :indeterminate="loading"
                    rounded
                    height="3"
                    style="margin-top: -6px;"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card tile class="pa-4 statisticsCardContainer" height="100%" flat>
          <div class="statisticsCard">
            <div class="mainStat">
              <span class="mainDataElement" id="witness_count">{{witnessCount}}</span>
              <span class="mainDataTitle">{{witnessName}}</span>
            </div>
            <div class="mt-2 secondaryStat">
              <span class="contextDataTitle">Total Witnessing Time</span>
              <span class="contextDataBox">{{witnessTotalTime}}</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm=12 md=4 class="pt-0">
        <v-card tile class="pa-4 statisticsCardContainer" height="100%" flat>
          <div class="statisticsCard">
            <div class="mainStat">
              <span class="mainDataElement" id="mismatch_count">{{mismatchCount}}</span>
              <span class="mainDataTitle">{{mismatchName}}</span>
            </div>
            <div class="mt-2 secondaryStat">
              <span class="contextDataElement">{{mismatchRatio}}</span>
              <span class="contextDataTitle">per 1000 Witness Points</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm=12 md=4 class="pt-0">
        <v-card tile class="pa-4 statisticsCardContainer" height="100%" flat>
          <div class="statisticsCard">
            <div class="mainStat">
              <span class="mainDataElement" id="assign_count">{{assignCount}}</span>
              <span class="mainDataTitle">{{assignName}}</span>
            </div>
            <div class="mt-2 secondaryStat">
              <span class="contextDataElement">{{assignRatio}}</span>
              <span class="contextDataTitle">per 1000 Witness Points</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm=12 md=4 class="pt-0">
        <v-card tile class="pa-4 statisticsCardContainer" height="100%" flat>
          <div class="statisticsCard">
            <div class="mainStat">
              <span class="mainDataElement" id="discards_count">{{discardCount}}</span>
              <span class="mainDataTitle">{{discardName}}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-container>
</template>

<style scoped>
* >>> .v-menu__content--auto {
  max-height: 400px !important;
}
.statisticsCardContainer {
  border-radius: 8px!important;
  border: 1px solid #005CAB;
  border-left: thick #005CAB solid!important;
}
.statisticsCard {
  height: 100%;
  color:#222;
}
.mainStat {
  display: flex;
  align-items: center;
}
.secondaryStat {
  display: flex;
  align-items: center;
}

.statisticsCard .mainDataElement {
  font-size: 70px;
  line-height: 70px;
  display: inline-block;
  margin-right: 15px;
  color:#222;
}

.statisticsCard .mainDataTitle {
  font-size: 25px;
  line-height: 70px;
  color:#222;
}

.statisticsCard .mainDataElement {
  font-size: 70px;
  line-height: 70px;
  display: inline-block;
  margin-right: 10px;
  color:#222;
}

.statisticsCard .contextDataBox {
  font-size: 18px;
  line-height: 18px;
  padding: 3px 6px 3px 6px;
  display:inline-block;
  margin-left: 6px;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  font-weight: bold;
}

.statisticsCard .contextDataElement {
  font-size: 18px;
  line-height: 18px;
  padding: 3px 6px;
  display:inline-block;
  background: #e4e4e4;
  margin-right: 6px;
  border-radius: 3px;
  font-weight: 500;
}

.statisticsCard .contextDataTitle {
  font-size: 16px;
  line-height: 24px;
}

</style>

<script>
import witnessStatisticsViewModel from '@/view-models/witness-statistics-view-model';

export default witnessStatisticsViewModel;

</script>